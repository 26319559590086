import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ReactPlayer from 'react-player';
import video from '../video/video1.mp4';
import './css/Proyectos.css';

import logo from '../imagenes/logoCarrera.png';

function Proyecto2() {
    return (
        <>
            <div className='proyectoCarrera py-4'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <ReactPlayer url={video} controls className='videoCarrera'></ReactPlayer>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            <div className='pCContent'>
                                <div className='titleCont'>
                                    <div className='pt-4 mb-4 d-flex justify-content-between w-100'>
                                        <h2 className='colorV'>Carrera Pro-Salud Mental</h2>
                                        <img src={logo} className='logoFeli'></img>
                                    </div>
                                    <img />
                                </div>
                                <p className='textRight'>Es una carrera atlética que se organiza año con año en el mes de septiembre, en el marco del día internacional de la prevención del suicidio. Tiene como objetivo fomentar el cuidado de la salud mental por medio de la activación física y el deporte, además de generar conciencia sobre la prevención del suicidio.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
}

export default Proyecto2;