import NavBar from './NavBar';
import Carrusel from './Carrusel';
import QuienesSomos from './QuienesSomos';
import Mision from './Mision';
import Historia from './Historia';
import EjesAccion from './EjesAccion';
import Equipo from './Equipo';
import Footer from '../Footer';
import Organigrama from './Organigrama';

function Inicio() {
    return (
        <>
        <NavBar></NavBar>
        <Carrusel></Carrusel>
        <QuienesSomos></QuienesSomos>
        <Mision></Mision>
        <Historia></Historia>
        <EjesAccion></EjesAccion>
        <Equipo></Equipo>
        <Organigrama></Organigrama>
        <Footer></Footer>
        </>
    );
}

export default Inicio;