import './css/NavBar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import fbIconBlack from '../imagenes/FacebookIconNav.svg';
import ytIconBlack from '../imagenes/YoutubeIconNav.svg';
import liIconBlack from '../imagenes/LinkedInIconNav.svg';
import instIconBlack from '../imagenes/InstaIconNav.svg';
import fbIcon from '../imagenes/FacebookIcon.svg';
import ytIcon from '../imagenes/YoutubeIcon.svg';
import liIcon from '../imagenes/LinkedInIcon.svg';
import instIcon from '../imagenes/InstaIcon.svg';

import { Link } from 'react-router-dom';
import logo from '../imagenes/logo.svg';

function NavBar() {
    return (
        <Navbar expand="lg" className="navTrans navbar-dark">
        <Container>
          <Navbar.Brand>
            <img src={logo} className='logoNav'/>
              </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='menuContainer'>
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="proyectos" className="menuItem">PROYECTOS</Nav.Link>
              <Nav.Link as={Link} to="aliados" className="menuItem">ALIADOS</Nav.Link>
              <Nav.Link as={Link} to="contacto" className="menuItem">CONTACTO</Nav.Link>
              <Nav.Link as={Link} to="apoyar" className="menuItem">CÓMO APOYAR</Nav.Link>
              <div className='navSocial'>
              <Nav.Link href="https://www.facebook.com/lideresdealtura" className="menuItem"><img src={fbIcon} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.instagram.com/lideresdealturaac" className="menuItem"><img src={instIcon} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.linkedin.com/in/viridianalvarez/" className="menuItem"><img src={liIcon} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.youtube.com/@LideresdeAltura" className="menuItem"><img src={ytIcon} className='menuIcon'/></Nav.Link>
              </div>
              <div className='navSocialBlack'>
              <Nav.Link href="https://www.facebook.com/lideresdealtura" className="menuItem menuIconMobile"><img src={fbIconBlack} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.instagram.com/lideresdealturaac" className="menuItem menuIconMobile"><img src={instIconBlack} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.linkedin.com/in/viridianalvarez/" className="menuItem menuIconMobile"><img src={liIconBlack} className='menuIcon'/></Nav.Link>
              <Nav.Link href="https://www.youtube.com/@LideresdeAltura" className="menuItem menuIconMobile"><img src={ytIconBlack} className='menuIcon'/></Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default NavBar;