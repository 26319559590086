import './css/EjesAccion.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import educacion from '../imagenes/educacion.svg';
import salud from '../imagenes/salud.svg';
import crecimiento from '../imagenes/crecimiento.svg';
import igualdad from '../imagenes/igualdadGenero.svg';
import desarrollo from '../imagenes/desarrolloSustentable.svg';

function EjesAccion() {
    return (
        <>
            <div className='ejesAccion'>
                <Container className='ejesContainer'>
                    <div className='d-flex'><h2 className='tituloSeccVerde'>PRINCIPALES EJES DE ACCIÓN</h2></div>
                    <p className='textoSecundario'>Los ejes de nuestra Fundación están alineados a los objetivos de desarrollo sostenible de la ONU.</p>
                    <Row className='justify-content-center'>
                        <Col lg={2} md={2} xs={6}>
                            <img src={educacion} className='ejeImg'></img>
                            <p className='ejeText'>Fortalecimiento, mejora y acceso a la Educación de Calidad.</p>
                        </Col>
                        <Col lg={2} md={2} xs={6}>
                            <img src={salud} className='ejeImg'></img>
                            <p className='ejeText'>Salud integral y bienestar.</p>
                        </Col>
                        <Col lg={2} md={2} xs={6}>
                            <img src={crecimiento} className='ejeImg'></img>
                            <p className='ejeText'>Crecimiento económico y emprendedurismo para el desarrollo social.</p>
                        </Col>
                        <Col lg={2} md={2} xs={6}>
                            <img src={igualdad} className='ejeImg'></img>
                            <p className='ejeText'>Fortalecimiento y desarrollo de competencias sobre  igualdad de género.</p>
                        </Col>
                        <Col lg={2} md={2} xs={6}>
                            <img src={desarrollo} className='ejeImg'></img>
                            <p className='ejeText'>Respeto y cuidado de la comunidad de vida para el desarrollo sustentable.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default EjesAccion;