import { Nav } from 'react-bootstrap';
import Footer from '../Footer';
import NavVerde from '../NavVerde';
import Cabecera from './Cabecera';
import Proyecto1 from './Proyecto1';
import Proyecto2 from './Proyecto2';
import Proyecto3 from './Proyecto3';
import Proyecto4 from './Proyecto4';
import Galeria from './Galeria';
function Proyectos() {
    return (
        <>
            <NavVerde></NavVerde>
            <Cabecera></Cabecera>
            <Proyecto1></Proyecto1>
            <Proyecto2></Proyecto2>
            <Proyecto3></Proyecto3>
            <Proyecto4></Proyecto4>
            <Galeria></Galeria>
            <Footer></Footer>
        </>
    );
}

export default Proyectos;