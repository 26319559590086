import Footer from '../Footer';
import NavVerde from '../NavVerde';
import Colaboradores from './Colaboradores';
function Aliados() {
    return (
        <>
            <NavVerde></NavVerde>
            <Colaboradores></Colaboradores>
            <Footer></Footer>
        </>
    );
}

export default Aliados;