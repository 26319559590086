import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import './css/Historia.css';

function Historia() {
    return (
        <>
            <div className='historia mt-4 mb-4'>
                <h2 className='tituloSeccion'>NUESTRA HISTORIA</h2>
                <Row className='justify-content-center w-100 histRow'>
                    <Col lg={6} md={6} xs={12} className='histCol'>
                        <div className='cont'>
                            <div className='box F2014'>
                                <h3 className='tituloFecha'><span className='fecha'>2 julio - </span>Constitución de Líderes de Altura A.C.</h3>
                                <p className='dateDesc'>En el estado de Aguascalientes, siendo sus miembros fundadores la Mtra. Viridiana Álvarez Chávez y el Lic. Jorge Luis Álvarez Chávez. Durante varios años desarrollamos proyectos para ayudar a sectores vulnerables, promover valores, el liderazgo transformacional y la participación ciudadana en actividades de desarrollo social.</p>
                            </div>

                            <div className='box F2021'>
                                <h3 className='tituloFecha'><span className='fecha'>Septiembre - </span>Reestructuración e integración de nuevos asociados a la Fundación.</h3>
                                <p className='dateDesc'>Se integraron nuevos asociados fortaleciendo el anhelo de transformar vidas de una manera positiva, desarrollando proyectos más estructurados y con una metodología más efectiva.</p>
                            </div>

                            <div className='box F2021 pb-4'>
                                <h3 className='tituloFecha'><span className='fecha'>Agosto - </span>Inicio del Proyecto FELí.</h3>
                            </div>
                            <div className='box F2022 pb-4'>
                                <h3 className='tituloFecha'><span className='fecha'>Septiembre - </span>Inicio del Proyecto Plasma el Amor por la Vida.</h3>
                            </div>
                            <div className='box F2022 pb-4'>
                                <h3 className='tituloFecha'><span className='fecha'>Septiembre  - </span>Primera Carrera Pro-Salud Mental.</h3>
                            </div>
                            <div className='box F2024'>
                            <h3 className='tituloFecha'>Hemos trabajado de manera <span className='fecha'>ininterrumpida </span>desde nuestra constitución hasta la actualidad.</h3>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Historia;