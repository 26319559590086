import  Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './css/Colaboradores.css';

import sedeso from "../imagenes/Aliados/sedeso.jpg";
import ledu from "../imagenes/Aliados/ledu.jpg";
import uaa from "../imagenes/Aliados/uaa.jpg";
import galloazul from "../imagenes/Aliados/galloazul.jpg";
import globaluni from "../imagenes/Aliados/globaluni.jpg";
import tecmty from "../imagenes/Aliados/tecmty.jpg";
import uvm from "../imagenes/Aliados/uvm.jpg";
import pixeled from "../imagenes/Aliados/pixeled.jpg";
import lasala from "../imagenes/Aliados/lasala.jpg";
import exa from "../imagenes/Aliados/exa.jpg";
import idea from "../imagenes/Aliados/idea.jpg";

import klasse from "../imagenes/Aliados/klasse.jpg";
import bife from "../imagenes/Aliados/bife.jpg";
import donaldson from "../imagenes/Aliados/donaldson.jpg";
import vitrohogar from "../imagenes/Aliados/vitrohogar.jpg";
import realasientos from "../imagenes/Aliados/realAsientos.jpg";
import centralgas from "../imagenes/Aliados/centralgas.jpg";
import century21 from "../imagenes/Aliados/century21.jpg";
import electrolit from "../imagenes/Aliados/electrolit.jpg";
import ciraf from "../imagenes/Aliados/ciraf.jpg";
import lovehugs from "../imagenes/Aliados/lovehugs.jpg";
import padma from "../imagenes/Aliados/padma.jpg";
import terrezaitaliana from "../imagenes/Aliados/terrazaitaliana.jpg";
import habitania from "../imagenes/Aliados/habitania.jpg";
import global from "../imagenes/Aliados/global.jpg";
import powerade from "../imagenes/Aliados/powerade.jpg";
import sanati from "../imagenes/Aliados/sanati.jpg";
import wyndham from "../imagenes/Aliados/wyndham.jpg";
import fox from "../imagenes/Aliados/fox.jpg";
import puertocamaron from "../imagenes/Aliados/puertoCamaron.jpg";
import laspaps from "../imagenes/Aliados/laspaps.jpg";
import ais from "../imagenes/Aliados/ais.jpg";
import cuatrocaminos from "../imagenes/Aliados/cuatrocaminos.jpg";
import ferreteriaviramontes from "../imagenes/Aliados/ferreteriaviramontes.jpg";
import dulceriapariente from "../imagenes/Aliados/dulceriaselpariente.jpg";
import mangoSpace from "../imagenes/Aliados/mangoSpace.jpg";

function Colaboradores() {
    return (
        <>
            <Container>
                <div className='d-flex mt-4'><h1 className='tituloSeccion tituloBig'>ALIADOS</h1></div>
                <p className="textoAgrad">Agradecemos de corazón a todas las personas que confían en nosotros y nos dan su apoyo para seguir trabajando en la construcción de una mejor sociedad.</p>
                <div className='d-flex mt-4'><h2 className='tituloSeccion'>ALIADOS / COLABORADORES</h2></div>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={sedeso} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={ledu} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={uaa} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={galloazul} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={globaluni} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={tecmty} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={uvm} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={pixeled} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={lasala} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={exa} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={idea} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={mangoSpace} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
                <div className='d-flex'><h2 className='tituloSeccion'>NOS HAN APOYADO</h2></div>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={klasse} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={bife} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={donaldson} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={vitrohogar} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={realasientos} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={centralgas} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={century21} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={electrolit} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={ciraf} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={lovehugs} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={padma} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={terrezaitaliana} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={habitania} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={global} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={powerade} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={sanati} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={wyndham} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={fox} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6}>
                    <img src={puertocamaron} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={laspaps} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={ais} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={cuatrocaminos} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={ferreteriaviramontes} alt="" width="100%"  className="colabImg"/>
                    </Col>
                    <Col lg={2} md={2} xs={6}>
                    <img src={dulceriapariente} alt="" width="100%"  className="colabImg"/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Colaboradores;