import './css/Equipo.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import foto1 from '../imagenes/foto1.png';
import jorgeLuis from '../imagenes/jorgeLuis.png';
import juanCarlos from '../imagenes/juanCarlos.png';
import franciscoTirado from '../imagenes/FranciscoTirado.png';
import eduardoGama from '../imagenes/eduardoGama.png';
import alfonsoMonroy from '../imagenes/AlfonsoMonroy.png';
import ignacioCalderon from '../imagenes/IgnacioCalderon.png';
import marisaGarcia from '../imagenes/MarisaGarcia.png';
import raulDeLuna from '../imagenes/RauldeLuna.png';
import imgTeam1 from '../imagenes/imgTeam1.jpg';
import imgTeam2 from '../imagenes/SIPINNA.jpeg';
import placeHolder from '../imagenes/circlePlaceHolder.png';

function Equipo() {
    return (
        <>
            <div className='equipo'>
                <Container className="mb-4">
                    <div className='d-flex mb-3'><h2 className='tituloSecEquipo'>NUESTRO EQUIPO</h2></div>
                    <Row className='justify-content-between mb-4 '>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={foto1} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Presidenta:</p>
                                    <p className='nombre'>Viridiana Álvarez Chávez</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={jorgeLuis} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Director General: </p>
                                    <p className='nombre'>Jorge Luis Álvarez Chávez</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={alfonsoMonroy} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Tesorero:</p>
                                    <p className='nombre'>Alfonso Felipe Monroy Domínguez</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={marisaGarcia} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Directora de Comunicación:</p>
                                    <p className='nombre'>Marisa García Ruiz</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={eduardoGama} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Asociado: </p>
                                    <p className='nombre'>Eduardo Gama Abuasale</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='justify-content-between'>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={franciscoTirado} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Asociado:</p>
                                    <p className='nombre'>José Francisco Tirado Guzmán</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={raulDeLuna} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Asociado:</p>
                                    <p className='nombre'>Raúl de Luna Vargas</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={ignacioCalderon} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Asociado: </p>
                                    <p className='nombre'>Ignacio Calderón López</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={placeHolder} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Asociado:</p>
                                    <p className='nombre'>José Manuel de Loera Rizo</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={2} xs={12} className='cardsContainer'>
                            <Card className='tarjetaPer'>
                                <div className='d-flex justify-content-center'><img src={juanCarlos} alt="" className='fotografia'></img></div>
                                <Card.Body>
                                    <p className='puesto'>Director de Producción y Medios:</p>
                                    <p className='nombre'>Juan Carlos Arredondo Dávila</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div className="somosParte">
                    <Row className='rowCont'>
                        <Col lg={6} md={6} xs={12} className='noPadding'>
                            <img src={imgTeam1} className='imgTeam'></img>
                        </Col>
                        <Col lg={6} md={6} xs={12} className='noPadding colEquipo2'>
                            <div className='textEquipoCont'>
                                <div className='mb-4'><h2 className='tituloSecSomos'>SOMOS PARTE DE</h2></div>
                                <div className='px-5'>
                                    <p className='textoSecSomos'>Formamos parte del Comité para el Fortalecimiento de las Asociaciones Civiles de la Secretaría de Desarrollo Social de Aguascalientes (2023-2026).</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className='rowCont'>

                        <Col lg={6} md={6} xs={12} className='noPadding colEquipo2'>
                            <div className='textEquipoCont'>
                                <div className='mb-4'><h2 className='tituloSecSomos'>FORMAMOS PARTE DE</h2></div>
                                <div className='px-5'>
                                    <p className='textoSecSomos'>Somos parte del consejo del Sistema de Protección Integral de los Derechos de las Niñas, Niños y Adolescentes del Estado de Aguascalientes (SIPINNA Aguascalientes).</p>
                                </div>
                            </div>
                        </Col>
                        
                        <Col lg={6} md={6} xs={12} className='noPadding'>
                            <img src={imgTeam2} className='imgTeam'></img>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Equipo;