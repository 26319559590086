import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import img1 from '../imagenes/Galeria/1.png';
import img2 from '../imagenes/Galeria/2.png';
import img3 from '../imagenes/Galeria/3.png';
import img4 from '../imagenes/Galeria/4.png';
import img5 from '../imagenes/Galeria/5.png';
import img6 from '../imagenes/Galeria/6.png';
import img7 from '../imagenes/Galeria/7.png';
import img8 from '../imagenes/Galeria/8.png';
import img9 from '../imagenes/Galeria/9.png';
import img10 from '../imagenes/Galeria/10.png';
import img11 from '../imagenes/Galeria/11.png';
import img12 from '../imagenes/Galeria/12.png';
import img13 from '../imagenes/Galeria/13.png';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
function Galeria() {
    return (
        <>
            <div className='proyectoLeft proyectoFeli'>
                <div className='proyectContent'>
                    <Container>
                        <div className='pt-4 mb-4 d-flex justify-content-between'>
                            <h2 className='tituloSeccVerde'>Galería Líderes de Altura</h2>
                        </div>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            additionalTransfrom={0}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className='p-3'>
                                <img src={img1} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img2} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img3} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img4} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img5} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img6} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img7} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img8} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img9} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img10} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img11} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img12} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img13} className='w-100 rounded projImg'></img>
                            </div>

                        </Carousel>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default Galeria;