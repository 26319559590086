import './App.css';

import { Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import Inicio from './Components/Inicio/Inicio';
import Proyectos from './Components/Proyectos/Proyectos';
import Aliados from './Components/Aliados/Aliados';
import ComoApoyar from './Components/Contacto/ComoApoyar';
import Contacto from './Components/Contacto/Contacto';

function App() {
  return (
    <div className="App">
      <HashRouter /*basename='/portal/'*/>
        <Routes>
          <Route path='' element={<Inicio></Inicio>} />
          <Route path='/proyectos' element={<Proyectos></Proyectos>} />
          <Route path='/aliados' element={<Aliados></Aliados>} />
          <Route path='/contacto' element={<Contacto></Contacto>} />
          <Route path='/apoyar' element={<ComoApoyar></ComoApoyar>} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
