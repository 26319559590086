import './css/NavVerde.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import fbIcon from './imagenes/FacebookIcon.svg';
import ytIcon from './imagenes/YoutubeIcon.svg';
import liIcon from './imagenes/LinkedInIcon.svg';
import instIcon from './imagenes/InstaIcon.svg';
import logo from './imagenes/logo.svg';

import { Link } from 'react-router-dom';


function NavVerde() {
  return (
    <Navbar expand="lg" className="bgGreen navbar-dark">
      <Container>
        <Navbar.Brand><img src={logo} className='logoNav' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="menuItemV">INICIO</Nav.Link>
            <Nav.Link as={Link} to="/proyectos" className="menuItemV">PROYECTOS</Nav.Link>
            <Nav.Link as={Link} to="/aliados" className="menuItemV">ALIADOS</Nav.Link>
            <Nav.Link as={Link} to="/contacto" className="menuItemV">CONTACTO</Nav.Link>
            <Nav.Link as={Link} to="/apoyar" className="menuItemV">CÓMO APOYAR</Nav.Link>
            <div className='navSocial'>
              <Nav.Link href="https://www.facebook.com/lideresdealtura" className="menuItem"><img src={fbIcon} className='menuIcon' /></Nav.Link>
              <Nav.Link href="https://www.instagram.com/lideresdealturaac" className="menuItem"><img src={instIcon} className='menuIcon' /></Nav.Link>
              <Nav.Link href="https://www.linkedin.com/in/viridianalvarez/" className="menuItem"><img src={liIcon} className='menuIcon' /></Nav.Link>
              <Nav.Link href="https://www.youtube.com/@LideresdeAltura" className="menuItem"><img src={ytIcon} className='menuIcon' /></Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavVerde;