import './css/Carrusel.css';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import banner from '../imagenes/Banner.jpg';
import banner1 from '../imagenes/Banner_new.jpg';


import ReactPlayer from 'react-player';
import video from '../video/vidHome.MOV';

function UncontrolledExample() {
  return (
    <>
    <Carousel>
      <Carousel.Item>
        <img src={banner1} alt='Lideres de altura' className='banner'/>
        <Carousel.Caption className='caption'>
          <h3 className='tituloCarrusel'>Fundación Líderes de Altura A.C. <br></br>Impulsamos proyectos <br></br><span className='tituloVer'>de alto valor social</span></h3>
          <p className='descripcion'>Para coadyuvar en la construcción de una comunidad más justa, equitativa y humana</p>

          <ReactPlayer url={video} controls className='videoHome'></ReactPlayer>
          

          
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <div className='marcaCont'><h2 className='marca'>Impulsamos proyectos de alto valor social</h2></div>
    </>
  );
}

export default UncontrolledExample;

/**Impulsamos proyectos <br></br><span className='tituloVer'>de alto valor social</span> */