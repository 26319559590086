import Footer from '../Footer';
import NavVerde from '../NavVerde';
import './css/ComoApoyar.css'
import bg from '../imagenes/ProyectsBanner.jpg';
import logo from "../imagenes/logoColor.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Aliados() {
    return (
        <>
            <NavVerde></NavVerde>
            <div className='projectsBanner comoApoyarBanner'>
                <h1 className='pageTitle'>CÓMO APOYAR</h1>
            </div>
            <Container>
                <Row className='justify-content-center mt-4'>
                    <Col lg={8} md={8} xs={12}>
                        <p className='txtAgrad'>Con tu apoyo podemos ayudar a cambiar la vida de cientos de personas y construir un mejor México más justo, equitativo y humano.</p>
                        <p className='txtAgrad1'>¡Gracias de corazón por ser parte e impulsar nuestros proyectos!</p>
                    </Col>
                </Row>
            </Container>
            <Container className='mt-5 mb-5'>
                <h2 className='datosTitulo'>DATOS BANCARIOS</h2>
                <Row className='mt-4'>
                    <Col lg={6} md={6} xs={12}>
                        <img src={logo} alt="" className='logoApoyar' />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                        <p className='datosB'>Cuenta BANORTE</p>
                        <p className='datosB'>Lideres de Altura AC</p>
                        <p className='datosB'><span className='textGreen'>Cuenta: </span>1162078681</p>
                        <p className='datosB'><span className='textGreen'>CLABE: </span>072010011620786818</p>
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </>
    );
}

export default Aliados;