import './css/Footer.css';
import { Row, Col, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import logo from './imagenes/logo.svg';
import fbIcon from './imagenes/FacebookIcon.svg';
import instIcon from './imagenes/InstaIcon.svg';
import ytIcon from './imagenes/YoutubeIcon.svg';
import liIcon from './imagenes/LinkedInIcon.svg';

import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <div className='footer'>
                <Container>
                    <Row className='jsutify-content-center'>
                        <Col lg={3} md={3} xs={12} className='colF'>
                            <img src={logo} className='logo' />
                            <Row className='socialMedia'>
                                <Col>
                                    <a href='https://www.facebook.com/lideresdealtura'><img src={fbIcon} className='socialIcon'/></a>
                                </Col>
                                <Col>
                                   <a href='https://www.instagram.com/lideresdealturaac'><img src={instIcon} className='socialIcon'/></a>
                                </Col>
                                <Col>
                                    <a href='https://www.youtube.com/@LideresdeAltura'><img src={ytIcon} className='socialIcon'/></a>
                                </Col>
                                <Col>
                                    <a href='https://www.linkedin.com/in/viridianalvarez/'><img src={liIcon} className='socialIcon'/></a>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} md={3} xs={12} className='colF'>
                            <p className='textoCab'>Contacto</p>
                            <p className='textoSec'>¡Siguenos en nuestras redes sociales!</p>
                            <p className='textoSec'>jorge@lideresdealtura.com</p>
                        </Col>
                        <Col lg={3} md={3} xs={12} className='colF'>
                            <p className='textoCab'>Enlaces</p>
                            <Nav className='footerNav'>
                                <Nav.Link as={Link} to="/" className='textoSec navNoPad'>Inicio</Nav.Link>
                                <Nav.Link as={Link} to="/proyectos" className='textoSec navNoPad'>Proyectos</Nav.Link>
                                <Nav.Link as={Link} to="/aliados" className='textoSec navNoPad'>Aliados</Nav.Link>
                                <Nav.Link as={Link} to="/contacto" className='textoSec navNoPad'>Contacto</Nav.Link>
                                <Nav.Link as={Link} to="/apoyar" className='textoSec navNoPad'>Como Apoyar</Nav.Link>
                            </Nav>
                        </Col>
                        <Col lg={3} md={3} xs={12} className='colF'>
                            <p className='textoCab'>Contribuir</p>
                            <p className='textoSec'>Ayúdanos a transformar el país a través de alguna de nuestras iniciativas y proyectos.</p>
                            <div className='d-flex buttonCont'><Button variant="primary" as={Link} to="/apoyar" className='rounded-pill btnGreen'>Contribuir</Button></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Footer;

/**<ul className='listaFooter'>
                                <li><a href='/' className='textoSec'>Inicio</a></li>
                                <li><a href='/proyectos' className='textoSec'>Proyectos</a></li>
                                <li><a href='/aliados' className='textoSec'>Aliados</a></li>
                                <li><a href='/contacto' className='textoSec'>Contacto</a></li>
                                <li><a className='textoSec'>Como apoyar</a></li>
                            </ul> */