import './css/QuienesSomos.css'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FotoGrupal from '../imagenes/FotoGrupal.jpg';
function QuienesSomos() {
    return (
        <>
            <div className='quienesSomos' id='nosotros'>
                <Row className='rowCont'>
                    <Col lg={6} md={6} xs={12} className='textContainer noPadding'>
                        <div className='contentContainer'>
                            <div className='d-flex'><h2 className='tituloSeccion'>¿QUIÉNES SOMOS?</h2></div>
                            <p className='descQuienes py-3'>Somos una asociación civil sin fines de lucro integrada por gente apasionada de ayudar, con un ideal fuerte de <strong>construir un mejor México</strong> y una mejor sociedad.</p>
                            
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} className="noPadding">
                        <img src={FotoGrupal} alt='lideres de altura' width="100%" />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default QuienesSomos;

/** <div className='d-flex justify-content-start'><Button variant="primary" className='rounded-pill btnGreen'>Ver video</Button></div>*/