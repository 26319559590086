import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo from '../imagenes/EscalandoSuenos/escalandoSueños.png';

import img1 from '../imagenes/EscalandoSuenos/1.jpg';
import img2 from '../imagenes/EscalandoSuenos/2.jpg';
import img3 from '../imagenes/EscalandoSuenos/3.jpg';
import img4 from '../imagenes/EscalandoSuenos/4.jpg';
import img5 from '../imagenes/EscalandoSuenos/5.jpg';
import img6 from '../imagenes/EscalandoSuenos/6.jpg';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
function Proyecto4() {
    return (
        <>
            <div className='proyectoLeft proyectoCarrera'>
                <div className='proyectContent'>
                    <Container>
                        <div className='pt-4 mb-4 d-flex justify-content-between'>
                            <h2 className='tituloSeccVerde'>Escalando Sueños</h2>
                            <img src={logo} className='logoEscalando'></img>
                        </div>
                        <p className='text-light proyDesc'>Escalando Sueños son un conjunto de conferencias que son impartidas por personas destacadas en algún ámbito profesional (deportivo, académico, artístico, empresarial, investigación, etc.). Su objetivo es inspirar a personas a través de casos de éxito y del liderazgo transformacional, promoviendo valores como el esfuerzo, dedicación, perseverancia, entre otros.</p>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            additionalTransfrom={0}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className='p-3'>
                                <img src={img1} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img2} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img3} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img4} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img5} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img6} className='w-100 rounded projImg'></img>
                            </div>

                        </Carousel>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default Proyecto4;