import './css/Cabecera.css';
import bg from '../imagenes/ProyectsBanner.jpg';
function Cabecera() {
    return (
        <>
            <div className='projectsBanner'>
                <img src={bg} className='w-100'></img>
                <h1 className='pageTitle'>Proyectos</h1>
            </div>
        </>
    );
}

export default Cabecera;