import './css/Proyectos.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo from '../imagenes/ProyectoFeli/Feli.png';

import img1 from '../imagenes/ProyectoFeli/1.jpg';
import img2 from '../imagenes/ProyectoFeli/2.jpg';
import img3 from '../imagenes/ProyectoFeli/3.jpg';
import img4 from '../imagenes/ProyectoFeli/4.jpg';
import img5 from '../imagenes/ProyectoFeli/5.jpg';
import img6 from '../imagenes/ProyectoFeli/6.jpg';
import img7 from '../imagenes/ProyectoFeli/7.jpg';
import img9 from '../imagenes/ProyectoFeli/9.jpg';
import img10 from '../imagenes/ProyectoFeli/10.jpg';
import img11 from '../imagenes/ProyectoFeli/11.jpg';
import img12 from '../imagenes/ProyectoFeli/12.jpg';
import img13 from '../imagenes/ProyectoFeli/13.jpg';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function Proyecto1() {
    return (
        <>
            <div className='proyectoLeft proyectoFeli'>
                <div className='proyectContent'>
                    <Container>
                        <div className='pt-4 mb-4 d-flex justify-content-between'>
                            <h2 className='tituloSeccVerde'>Proyecto FELí</h2>
                            <img src={logo} className='logoFeli'></img>
                        </div>
                        <p className='text-light proyDesc'>“FELí” significa Fortalecimiento Emocional para Líderes, es un proyecto que tiene como objetivo brindar a adolescentes de escuelas secundarias públicas herramientas que promuevan la identificación, reflexión, gestión y expresión sana de sus emociones, a través de actividades lúdicas y pláticas, para que desarrollen habilidades socioemocionales y puedan tener una mejora en su calidad de vida, generando factores protectores para disminuir las probabilidades de que generen algún trastorno conductual, así como la ideación e intento suicida.
                            También, tiene como objetivo identificar a las y los adolescentes con alto riesgo de ideación suicida para referenciar los casos de manera inmediata y que tengan apoyo psicológico/psiquiátrico de manera prioritaria (ayudando a salvar vidas).
                            Todas las herramientas, tanto diagnósticas como el Cuaderno de Trabajo de Habilidades Socioemocionales, son seleccionadas, adaptadas y/o desarrolladas por el Laboratorio de Regulación Emocional y Prevención del Suicidio de la Universidad Autónoma de Aguascalientes para su implementación en este proyecto, y son analizadas y aprobadas por el Comité de Bioética de la Universidad Autónoma de Aguascalientes.</p>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            additionalTransfrom={0}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className='p-3'>
                                <img src={img1} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img2} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img3} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img4} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img5} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img6} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img7} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img9} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img10} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img11} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img12} className='w-100 rounded'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img13} className='w-100 rounded'></img>
                            </div>

                        </Carousel>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default Proyecto1;