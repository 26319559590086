import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo from '../imagenes/amorVida.png';

import img1 from '../imagenes/AmorVida/1.png';
import img2 from '../imagenes/AmorVida/2.png';
import img3 from '../imagenes/AmorVida/3.png';
import img4 from '../imagenes/AmorVida/4.png';
import img5 from '../imagenes/AmorVida/5.png';
import img6 from '../imagenes/AmorVida/6.png';
import img7 from '../imagenes/AmorVida/7.png';
import img8 from '../imagenes/AmorVida/8.png';
import img9 from '../imagenes/AmorVida/9.png';
import img10 from '../imagenes/AmorVida/10.png';
import img11 from '../imagenes/AmorVida/11.png';
import img12 from '../imagenes/AmorVida/12.png';
import img13 from '../imagenes/AmorVida/13.png';
import img14 from '../imagenes/AmorVida/14.png';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
function Proyecto3() {
    return (
        <>
            <div className='proyectoLeft proyectoFeli'>
                <div className='proyectContent'>
                    <Container>
                        <div className='pt-4 mb-4 d-flex justify-content-between'>
                            <h2 className='tituloSeccVerde'>Plasma el Amor por la Vida</h2>
                            <img src={logo} className='logoAmor'></img>
                        </div>
                        <p className='text-light proyDesc'>Este proyecto tiene como objetivo promover la salud mental en el Estado de Aguascalientes por medio de eventos y campañas informativas para visibilizar, sensibilizar e informar a la sociedad sobre la importancia del cuidado de la salud integral, el desarrollo de habilidades socioemocionales y la expresión sana de las emociones, con el fin de prevenir el suicidio, los trastornos conductuales y las enfermedades mentales.
                    Todos los eventos son realizados con la colaboración y participación de expertos e investigadores de nivel nacional e internacional, para difundir información precisa y actualizada, fomentando la erradicación de estigmas socioculturales sobre la salud mental.</p>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            additionalTransfrom={0}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className='p-3'>
                                <img src={img1} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img2} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img3} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img4} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img5} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img6} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img7} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img8} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img9} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img10} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img11} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img12} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img13} className='w-100 rounded projImg'></img>
                            </div>
                            <div className='p-3'>
                                <img src={img14} className='w-100 rounded projImg'></img>
                            </div>

                        </Carousel>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default Proyecto3;