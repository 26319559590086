import './css/Organigrama.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import foto1 from '../imagenes/foto1.png';
import EO1 from '../imagenes/EO1.png';

function Organigrama() {
    return (
        <>
            <div className='ubicacion'>
                <Container className="py-4">

                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <div className='ubiTxtCont'>
                                <div className='d-flex mb-3 titleCont ubiTitle'><h2 className='tituloSecEquipo'>UBICACIÓN</h2></div>
                                <p className='ubicacionTxt'>Nos encontramos en la ciudad de Aguascalientes, en el estado de Aguascalientes. Desde 2014 hemos desarrollado proyectos que se han ejecutado en distintas zonas y municipios del estado.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            <iframe className='mapaHome' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236858.65411245206!2d-102.45774820724127!3d21.937762403201287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee656b52dc29%3A0xceec2d9ae760136b!2sZona%20metropolitana%20de%20Aguascalientes%2C%20Ags.!5e0!3m2!1ses-419!2smx!4v1710815060708!5m2!1ses-419!2smx" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='equipo'>
                <Container className="mb-4">
                    <div className='d-flex mb-3 titleCont'><h2 className='tituloSecEquipo'>ORGANIGRAMA</h2></div>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <img src={EO1} alt='' className='orgImg' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Organigrama;