import './css/Mision.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import iconFlag from '../imagenes/flag.svg';
import iconEye from '../imagenes/eye.svg';
import iconHeart from '../imagenes/hand.svg';

function Mision() {
    return (
        <>
            <div className='mision py-4'>
                <Container>
                <Row className='justify-content-center'>
                        <Col lg={3} md={3} xs={12} className='cardCont'>
                            <Card className='cardB'>
                                <div className='d-flex justify-content-center'>
                                    <img src={iconFlag} alt="mision" className='cardIcon'></img>
                                </div>
                                <Card.Body>
                                    <Card.Title className='tituloCard'>Misión</Card.Title>
                                    <Card.Text className='descCard'>
                                        Impulsar el desarrollo de proyectos de alto valor social para mejorar la calidad de vida de las personas y coadyuvar en la construcción de una comunidad más justa, equitativa y humana.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={3} md={3} xs={12} className='cardCont'>
                            <Card className='cardB'>
                                <div className='d-flex justify-content-center'>
                                    <img src={iconEye} alt="vision" className='cardIcon'></img>
                                </div>
                                <Card.Body>
                                    <Card.Title className='tituloCard'>Visión</Card.Title>
                                    <Card.Text className='descCard'>
                                        Ser una organización internacional que gestione proyectos de alto impacto sobre problemáticas de desigualdad(es), sus causas y necesidades, a través del desarrollo de competencias para la sustentabilidad.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={3} md={3} xs={12} className='cardCont'>
                            <Card className='cardB'>
                                <div className='d-flex justify-content-center'>
                                    <img src={iconHeart} alt="valores" className='cardIcon'></img>
                                </div>
                                <Card.Body>
                                    <Card.Title className='tituloCard'>Valores</Card.Title>
                                    <Card.Text className='descCard listContainer'>
                                        <ul className='lista'>
                                            <li>Inspiración</li>
                                            <li>Altruismo</li>
                                            <li>Generosidad</li>
                                            <li>Respeto</li>
                                            <li>Perseverancia</li>
                                            <li>Honestidad</li>
                                            <li>Transparencia</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Mision;