import Footer from '../Footer';
import NavVerde from '../NavVerde';
import './css/Contacto.css'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';

import whatsapp from '../imagenes/telefonoIcon.svg';
import mail from '../imagenes/emailIcon.svg';

function Aliados() {
    return (
        <>
            <NavVerde></NavVerde>
            <Container className='mt-5 mb-5'>
            <div className='mb-2 tituloContainer d-flex'><p className='tituloSeccion subtitle'>¡CONTÁCTANOS!</p></div>
                <Row className='justify-content-between'>
                    <Col lg={5}>
                        <p className='text-gray secondaryText textLeft txtJust'>Contáctanos o déjanos tus datos y nos comunicaremos contigo.</p>
                       
                        <div className='dataContainer'>
                            <img src={mail} alt='' className='footerIcon' />
                            <p className='text-gray data'><span className='text-gray-bold'>Correo: </span>jorge@lideresdealtura.com</p>
                        </div>
                        <p className='text-gray secondaryText textLeft txtJust'>Nos encontramos en la ciudad de Aguascalientes, en el estado de Aguascalientes, donde hemos desarrollado proyectos que se han ejecutado en distintas zonas y municipios del estado.</p>
                        <iframe className='mapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236858.65411245206!2d-102.45774820724127!3d21.937762403201287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee656b52dc29%3A0xceec2d9ae760136b!2sZona%20metropolitana%20de%20Aguascalientes%2C%20Ags.!5e0!3m2!1ses-419!2smx!4v1710815060708!5m2!1ses-419!2smx" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                    <Col lg={5}>
                        <div className='formContainer'>
                            <p className='text-light formTitle'>Dejanos tus datos</p>
                            <input type="email" className="form-control mb-3" id="exampleFormControlInput1" placeholder="Nombre*" />
                            <input type="email" className="form-control mb-3" id="exampleFormControlInput1" placeholder="Correo*" />
                            <input type="number" className="form-control mb-3" id="exampleFormControlInput1" placeholder="Teléfono*" />
                            
                            <textarea className="form-control mb-3" id="exampleFormControlTextarea1" rows="10" placeholder="Duda o mensaje"></textarea>
                            <div className='submitCnt'>
                                <Button variant="primary" className='rounded-pill submitBtn'>Enviar</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </>
    );
}

export default Aliados;